import "./members.css";

const Members = () => {
  return (
    <div id="members" className="container members-container">
      <h1 className="member-txt">Meet Teams</h1>
      <div className="member member-1">
        <div className="member-img img1"></div>
        <div className="member-info">
          <h1 className="name">Cheki Lhamo</h1>
          <h3 className="position">Frontend Developer</h3>
          <h4 className="about">
            Enthusiastic Information Technology graduate with a deep passion for
            cybersecurity, working as an Assistant Lecturer to share my
            knowledge and foster cybersecurity skills among students while
            embarking on a career in the field. My journey in the world of
            Information Technology has not only equipped me with a solid
            academic foundation but has also ignited a fervent interest in the
            realm of cybersecurity.
          </h4>
          <a
            href="https://www.facebook.com/lhamoCheki24"
            target={"blank"}
            className="contact-member"
          >
            <span>contact</span>
          </a>
        </div>
      </div>

      <div className="member member-2">
        <div className="member-img img2"></div>
        <div className="member-info">
          <h1 className="name">Pema Zangmo</h1>
          <h3 className="position">UI/UX</h3>
          <h4 className="about">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus
            maiores harum laborum vero recusandae? Cumque nam facere aspernatur
            autem eius!
          </h4>
          <a
            href="https://www.facebook.com/PeZangmo"
            target={"blank"}
            className="contact-member"
          >
            <span>contact</span>
          </a>
        </div>
      </div>

      <div className="member member-3">
        <div className="member-img img3"></div>
        <div className="member-info">
          <h1 className="name">Gyeltshen Wangdi</h1>
          <h3 className="position">Backend Developer</h3>
          <h4 className="about">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus
            maiores harum laborum vero recusandae? Cumque nam facere aspernatur
            autem eius!
          </h4>
          <a
            href="https://www.facebook.com/gyltshen.wangdi"
            target={"blank"}
            className="contact-member"
          >
            <span>contact</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Members;
