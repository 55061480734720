import "./footer.css";
import { BsMouse } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { TiSocialDribbble } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { TiSocialGithub } from "react-icons/ti";

function Footer() {
  return (
    <div id="footer" className="container footer-container">
      <h1>
        Thanks for visiting.{" "}
        <a href="#home">
          <h2>
            <BsMouse /> - Scroll up -
          </h2>
        </a>
      </h1>
      <div className="social-links">
        <a href="https://www.instagram.com/ugyel21/">
          <BsInstagram className="social" />
        </a>
        <a href="https://www.facebook.com/ugyel21?mibextid=ZbWKwL" target={"blank"}>
          {" "}
          <FaFacebookF className="social" />
        </a>
        <a href="#" target={"blank"}>
          {" "}
          <TiSocialDribbble className="social" />
        </a>

        <a href="https://www.youtube.com/channel/UCIzyuVGhbLUizb6F7mTd1yA" target={"blank"}>
          <TiSocialYoutube className="social" />
        </a>

        <a href="https://gitlab.com/Ugyel21" target={"blank"}>
          <TiSocialGithub className="social" />
        </a>
      </div>
    </div>
  );
}
export default Footer;
